.container {
    display: flex;
    justify-content: space-between;
    background-color: #bbd7ec;
    align-items: center;
    width: 100%;
    padding: 2.5rem 0;
    height: auto;
    overflow: hidden;
    position: relative;
  }
  
  .image {
    width: 48%;
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  .visible {
    opacity: 1;
  }
  
  .slideInLeft {
    position: relative;
    animation: slideLeftToCenter 1s ease forwards;
  }
  
  .slideInRight {
    position: relative;
    animation: slideRightToCenter 1s ease forwards;
  }
  
  @keyframes slideLeftToCenter {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideRightToCenter {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }