/* Include Open Sans font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

/* Default styling for larger screens */
.main {
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Enables the parallax effect */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  font-family: 'Open Sans', sans-serif;
  font-size: 34px;
  display: inline-block;
  padding: 1rem ;
  font-weight: 300;
  position: relative;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.9s ease, opacity 1s ease;
  color: white; /* Set the text color to white */
}

.isLoading .heading {
  transform: scale(0.9);
  opacity: 0;
}

.heading::before,
.heading::after {
  height: 0.5px;
  width: 100%;
  content: '';
  background: white;
  display: block;
  position: absolute;
  transition: width 0.4s ease;
  transition-delay: 0.8s;
}

.heading::before {
  top: 0;
  left: 0;
}

.heading::after {
  bottom: 0;
  right: 0;
}

.isLoading .heading::before,
.isLoading .heading::after {
  width: 0;
}

/* For screens smaller than 700px, adjust height to 50vh */
@media (max-width: 700px) {
  .main {
    background-attachment: scroll; /* Removes the parallax effect */
    height: 75vh; /* Adjust height to 50% of the viewport height */
  }

  .heading {
    font-size: 24px; /* Adjust font size for smaller screens */
  }
}