.container {
    background-color: #bbd7ec;
    color: #000000;

    padding: 2rem 0;
    text-align: center;
    font-family: 'Noto Sans', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .messageSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1rem;
  }
  
  .message {
    font-size: 100px;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  
  .footer {
    margin-top: 1rem;
    border-top: 1px solid black;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
  
  .socialMedia {
    display: flex;
    margin-top: 5rem;
    justify-content: center;
    gap: 10px;
    font-size: 2rem;
  }
  
  .icon {
    color: #000;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .icon:hover {
    color: yellow;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .message {
      font-size: 4rem;
    }
  
    .footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .socialMedia {
      margin-top: 2rem;
      gap: 5px;
      font-size: 1.5rem;
    }
  }