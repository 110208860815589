@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mainContainer {
  font-family: 'Open Sans', sans-serif;
  color: white;
  background-color: #000000;
  font-weight: 100;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;

  animation: fadeIn 1.5s ease-in-out;
}

.header {
  text-align: left; /* Text align left */
  margin: 10px auto;
  animation: fadeIn 0.8s ease-in-out;
}

.title {
  text-transform: uppercase;
  font-size: 30px;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;

  font-weight: 100;
  color: #bbd7ec;
  margin-bottom: 5rem;
  letter-spacing: 0.05em;
}

.mainContent {
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
  text-align: left;
  padding-top: 1px;
}

.firstParagraph, .otherParagraph {
  margin-left: 2rem;
  margin-right: 2rem;
  line-height: 1.5; /* Adjusted for better readability */
  text-align: left;
  margin-bottom: 1rem; /* Reduce space between paragraphs */
}

.dropCap {
  float: left;
  font-size: 5rem;
  line-height: 4rem;
  padding-right: 0px;
  font-weight: bold;
  color: white;
}

.dropCap {
  float: left;
  font-size: 6rem;
  padding-right: 20px;
  padding-top: 5px; /* Adjust vertical alignment */
  padding-left: 5px;
  color: #bbd7ec;
}

.linkStyle {
  text-decoration: none;
  color: #bbd7ec;
  font-weight: 500;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.hidden {
  opacity: 0;
  transform: translateY(30px);
}