.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  background-color: #bbd7ec;
  transition: transform 0.3s ease-in-out;
}

.headerShow {
  transform: translateY(0);
}

.headerHide {
  transform: translateY(-100%);
}

.nav {
  display: flex;
  background-color: #bbd7ec;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

/* Left: Logo */
.brand {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(6rem);
}

.logo {
  width: auto;
  height: auto;
  max-height: 325px;
  margin: 0 auto;
}

/* Center: Navigation Menu */
.navMenu {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  display: flex;
  margin: 0 auto;
  list-style: none;
  position: absolute;
  transform: translateX(-50%);
}

.menuItem {
  position: relative;
  cursor: pointer;
}

.styledLink {
  text-decoration: none;
  color: #000000;
  font-size: 22px;
  font-weight: 300;
  transition: color 0.3s ease;
}

.styledLink:hover {
  color: white;
  text-decoration: none;
}

/* Dropdown Menu */
.dropdown {
  position: absolute;
  top: 50%;
  left: 0;
  text-decoration: none;
  background-color: #000000;
  z-index: 100;
  list-style: none;
  padding: 0.15rem;
  min-width: 350px;
}

.dropdownItem {
  padding: 1rem;
  cursor: pointer;
}

.dropdownLink {
  text-decoration: none;
  color: #ffffff;
  font-size: 28px;
  display: block;
}

/* Right: Free Consultation Button */
.consultationButton {
  background-color: #000000;
  color: #ffffff;
  font-weight: 300;
  border: 1px solid #bbd7ec;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 50%;  
  top: 600px;
  transform: translateX(-50%);
}

/* Fullscreen overlay menu with slide-down animation */
.fullscreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bbd7ec;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%); /* Start off-screen */
  transition: transform 0.5s ease; /* Smooth slide-down transition */
}

.fullscreenMenuOpen {
  transform: translateY(0); /* Slide to the visible position */
}

.fullscreenNav {
  list-style: none;
  text-align: center;
}

.fullscreenLink {
  font-size: 58px;
  text-decoration: none;
  margin: 10px auto;
  color: white;
  font-weight: 300;
}

/* Media Queries */
@media (max-width: 1000px) {
  .nav {
    padding: 15px;
  }

  .consultationButton {
    background-color: #000000;
    color: #ffffff;
    font-weight: 300;
    border: 1px solid #bbd7ec;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Logo adjustments for smaller screens */
  .brand {
    transform: translateX(-50%) translateY(3rem);
  }

  .logo {
    min-height: 100px;
    max-height: 150px;
  }

  .navMenu {
    display: none;
  }
}