* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
}

body {
  background-color: #bbd7ec;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  text-align: center;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
}

p {
  text-align: center;
}

.pricing {
  display: grid;
  max-width: 85%;
  margin: 0 auto;
  grid-template-columns: repeat(3, 1fr); /* Ensure 3 columns on larger screens */
  gap: 1.5rem; /* Increased horizontal and vertical gaps */
  padding: 1rem auto;
}

.plan {
  background-color: #fcfaf4;
  padding: 1.5rem 0.75rem;
  border-radius: 5px;
  text-align: center;
  transition: transform 0.5s, box-shadow 0.5s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto; /* Adjust to content size */
  width: 100%; /* Ensures full width for grid columns */
  position: relative; /* Allow for absolute positioning of the label */
}

/* Unique border colors for each plan */
.plan.starter {
  border: 10px solid #f2d600; /* Yellow border for Starter plan */
}

.plan.popular {
  border: 15px solid #bbd7ec; /* Blue border for Most Popular */
  transform: scale(1.1); /* Slightly increase the size */
  z-index: 1; /* Bring the middle card to the front */
}



.plan.value {
  border: 10px solid #ff6f61; /* Red border for Best Value */
}

.planLabel {
  background-color: #bbd7ec;
  color: #fff;
  padding: 0.75rem;
  font-size: 22px;
  font-weight: bold;
  border-radius: 5px;
  position: absolute;
  top: -30px; /* Place above the plan */
  left: 50%;
  transform: translateX(-50%);
}

.price {
  font-size: 2.25rem;
  color: #333;
  margin: 1rem 0 0rem 0; /* Ensures enough space between the label and the price */
}

.plan .features {
  list-style-type: none;
  text-align: center;
  padding-left: 0;
}

.plan .features li {
  margin: 8px 0;
  transition: transform 0.3s, font-size 0.3s;
}

/* Hover effect to enlarge text */
.plan .features li:hover {
  transform: scale(1.05);
  font-size: 1rem;
}

.plan button {
  align-self: flex-end;
  border: none;
  width: 50%;
  padding: 1rem 0.5rem;
  background-color: #bbd7ec;
  color: #fcfaf4;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto;
  margin-top: 1rem;
  transition: transform 0.3s, font-size 0.3s;
}

/* Hover effect to enlarge buttons */
.plan button:hover {
  transform: scale(1.15);
  font-size: 1rem;
}

.plan:hover {
  transform: scale(1.05);
}

/* Responsive styling */

/* Adjust to 2 columns on medium screens */
@media (max-width: 1024px) {
  .pricing {
    grid-template-columns: repeat(2, 1fr); /* Switch to 2 columns */
    gap: 1.5rem; /* Maintain gap */
  }
}

/* Stack plans vertically on small screens */
@media (max-width: 768px) {
  .pricing {
    grid-template-columns: 1fr; /* Stack cards vertically */
    gap: 1.5rem; /* Added gap for smaller screens */
    padding: 1rem;
  }

  .plan {
    width: 100%; /* Full width for small screens */
    padding: 2rem 1.5rem; /* Add padding to reduce tightness */
  }

  .plan.popular {
    transform: scale(1.05); /* Scale down slightly on smaller screens */
  }
}

/* Further adjust layout on very small screens */
@media (max-width: 480px) {
  .plan {
    padding: 2.5rem 1rem; /* Further reduce padding on extra small screens */
  }

  .planLabel {
    font-size: 16px; /* Make the plan label smaller */
    padding: 0.75rem;
  }

  .price {
    font-size: 1.8rem; /* Reduce price font size for small screens */
  }

  .plan button {
    padding: 0.75rem 0.5rem;
    font-size: 14px;
  }

  .plan button:hover {
    transform: scale(1.05); /* Reduce hover effect */
    font-size: 14px;
  }
}